// export const downloadImage = (imgsrc,name) => {
//   let image = new Image();
//   image.setAttribute("crossOrigin", "anonymous");
//   image.onload = function() {
//     let canvas = document.createElement("canvas");
//     canvas.width = image.width;
//     canvas.height = image.height;
//     let context = canvas.getContext("2d");
//     context.drawImage(image, 0, 0, image.width, image.height);
//     let url = canvas.toDataURL("image/png",0.7);
//     let a = document.createElement("a"); 
//     let event = new MouseEvent("click"); 
//     a.download = name?name+'.png':"photo.png";
//     a.href = url; 
//     a.dispatchEvent(event); 
//   };
//   image.src = imgsrc;
//   }

  export const downloadImage = (url,name) => {
    fetch(url).then(res => {
      res.blob().then(blob => {
        let a = document.createElement('a')
        let url = window.URL.createObjectURL(blob)
        let filename = name || '图片.png'
        a.href = url
        a.download = filename
        a.click()
        window.URL.revokeObjectURL(url)
      })
    })
    }

  export const getImageUrl = url => {
    if (!url || url.startsWith('http') || url.startsWith('https')) {
      return url
    }
    return (
      process.env.NODE_ENV === 'production'
        ? window.location.protocol + '//' + window.location.host + '/storage/' + url
        : process.env.VUE_APP_HOST + '/storage/' + url
    )
  }