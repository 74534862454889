import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirectr: '/comment',
    component: () => import('../views/comment.vue')
  },
  {
    path: '/pickupGuidelines',
    component: () => import('../views/pickupGuidelines.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title =  to.path == '/pickupGuidelines'?'取件指引':'評價'
  next()
})

export default router
