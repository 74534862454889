<template>
  <div class="uploadAvatars">
    <div
      v-for="(item, index) in value"
      :key="item"
      class="uploadAvatar"
      @mouseover="changeHover(index, true)"
      @mouseleave="changeHover(index, false)"
    >
      <el-image
        :src="getImageUrl(item)"
        fit="cover"
      />
      <div v-show="hovers[index]" class="actions">
        <span class="icon" type='查看'  @click="showImage(index)">
          <i class="el-icon-zoom-in" />
        </span>
        <span class="icon" type='下載'  @click="downloadImage(item)">
          <i class="el-icon-download" />
        </span>
        <span v-if="!disabled" type='刪除' class="icon" @click="delImage(index)">
          <i class="el-icon-delete" />
        </span>
      </div>
    </div>

    <div
      v-if="(maxCount === 0 || maxCount > value.length) && !disabled"
      v-loading="loading"
      element-loading-text="上傳中"
      class="uploadAvatar"
    >
      <label title="點擊上傳文件" @drop.prevent="dropUpload" @dragover.prevent>
        <input
          type="file"
          accept="image/*"
          style="opacity: 0; width: 100%; height: 100%; cursor: pointer"
          @change="fileChange"
        >
      </label>
      <i class="el-icon-plus" />
    </div>

    <el-image-viewer
      v-if="showViewer"
      :initial-index="initialIndex"
      :on-close="onClose"
      :url-list="value"
      :z-index="3000"
    />
  </div>
</template>

<script>
import { getImageUrl } from '@/uilt/index'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { fileUploadImage,filedownloadFile } from '@/uilt/api'
import ImageCompressor from 'compressorjs'
export default {
  components: {
    ElImageViewer
  },
  model: {
    prop: 'modelvalue',
    event: 'change'
  },
  props: {
    modelvalue: {
      type: Array,
      default: () =>[]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxCount: { // 最大上傳數，0 代表不限制
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      loading: false,
      hovers: [],
      showViewer: false,
      initialIndex: 0,
      value:[]
    }
  },
  watch: {
    value() {
      this.hovers = []
    }
  },
  mounted(){
    this.value = this.modelvalue
  },
  methods: {
    getImageUrl,
   async downloadImage(item){
     let res = await filedownloadFile({url:item})
      const blob = new Blob([res]);
        const fileName = '評價圖片.png';
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL'];
        const elink = document.createElement('a');
        if ('download' in elink) {
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = selfURL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          selfURL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);

    }
    },

    fileChange(e) {
      const file = e.target.files[0]
      e.target.value = ''

      const type = file.type.split('/').shift()
      if (type !== 'image') {
        return this.$message.warning('只能上傳圖片!')
      }

      this.upload(file)
    },
    dropUpload(e) {
      const file = e.dataTransfer.files[0]

      const type = file.type.split('/').shift()
      if (type !== 'image') {
        return this.$message.warning('只能上傳圖片!')
      }

      this.upload(file)
    },
    async upload(file) {
        this.publicImageCompression(file, 1920, 1920, 2, 0.7).then(res => {
          this.shangchuang(res)
        })
    },
    async shangchuang(file) {
      const param = new FormData()
      param.append('image', file, file.name)
      this.loading = true
      try {
        const res = await fileUploadImage(param)
        const { path } = res.data
        this.value.push(path)
        this.$emit('change', this.value)
      } finally {
        this.loading = false
      }
    },
    changeHover(index, bool) {
      this.$set(this.hovers, index, bool)
    },
    showImage(index) {
      this.initialIndex = index
      this.showViewer = true
    },
    onClose() {
      this.showViewer = false
    },
    delImage(index) {
      this.value.splice(index, 1)
      this.$emit('change', this.value)
      this.changeHover(index, false)
    },

    publicImageCompression(file, maxWidth = 2000, maxHeight = 2000, maxSize = 5, quality = 1) {
      return new Promise((resolve, reject) => {
        const options = {
          success(result) {
            resolve(result)
          },
          error(e) {
            reject(e)
          }
        }
        console.log(file)
        if (file.size > maxSize * 1024 * 1024) {
          options.quality = quality // 压缩质量
          options.maxWidth = maxWidth // 壓縮的圖片寬度最大值
          options.maxHeight = maxHeight // 壓縮的圖片高度最大值
          options.convertSize = true // 进行图像尺寸的调整
        }
        new ImageCompressor(file, options)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.uploadAvatars {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
  .uploadAvatar {
    border: 1px solid #c0ccda;
    overflow: hidden;
    border-radius: 6px;
    width: 140px;
    height: 140px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 10px 0;
    label {
      display: inline-block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      z-index: 1;
    }
    .el-icon-plus {
      position: absolute;
      font-size: 28px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .actions {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      cursor: default;
      background-color: rgba(0,0,0,.5);
      transition: all .3s;
      font-size: 20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        cursor: pointer;
        margin-left: 15px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .is-error {
    .uploadAvatar {
      border-color: red;
    }
  }
}
</style>
