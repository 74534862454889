import request from './axios'

// POST請求
export function redeem (data) {
  return request({
    url: '/qrcode/redeem',
    method: 'post',
    data: data
  })
}
export function getQrcode (data) {
  return request({
    url: '/qrcode/getQrcode',
    method: 'post',
    data: data
  })
}

export function fileUploadImage(data) {
  return request({
    url: '/upload',
    method: 'post',
    timeout: 0,
    data
  })
}

export function filedownloadFile(data) {
  return request({
    url: '/downloadFile',
    method: 'post',
    responseType:'blob',
    timeout: 0,
    data
  })
}

export function getComment(data) {
  return request({
    url: '/getComment',
    method: 'post',
    timeout: 0,
    data
  })
}

export function submitComment(data) {
  return request({
    url: '/submitComment',
    method: 'post',
    timeout: 0,
    data
  })
}

export function pickupGuide(data) {
  return request({
    url: '/pickupGuide',
    method: 'post',
    timeout: 0,
    data
  })
}


